<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
            <h5 slot="header" class="mb-0">
              <b-button
                size="sm"
                :to="{ name: 'admin.loan-request.list' }"
                class="mr-2"
                ><i class="fa fa-chevron-left"></i> Буцах</b-button
              >
              <span v-if="this.$route.params.id > 0"
                >Засах:
                <strong class="text-primary"
                  >{{ form.lastname }} {{ form.firstname }}</strong
                ></span
              >
              <span v-else>Шинээр нэмэх</span>
            </h5>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  id="input-group-lastname"
                  label="Овог:"
                  label-for="input-lastname"
                >
                  <b-form-input
                    id="input-lastname"
                    v-model="form.lastname"
                    type="text"
                    placeholder="Овог"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-firstname"
                  label="Нэр:"
                  label-for="input-firstname"
                >
                  <b-form-input
                    id="input-firstname"
                    v-model="form.firstname"
                    type="text"
                    placeholder="Нэр"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-register"
                  label="Регистр:"
                  label-for="input-register"
                >
                  <b-form-input
                    id="input-register"
                    v-model="form.register"
                    type="text"
                    placeholder="Регистр"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-phone"
                  label="Утас:"
                  label-for="input-phone"
                >
                  <b-form-input
                    id="input-phone"
                    v-model="form.phone"
                    type="text"
                    placeholder="Утас"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-term"
                  label="Хугацаа:"
                  label-for="input-term"
                >
                  <b-form-input
                    id="input-term"
                    v-model="form.term"
                    type="text"
                    placeholder="Хугацаа"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-interest"
                  label="Хүү:"
                  label-for="input-interest"
                >
                  <b-form-input
                    id="input-interest"
                    v-model="form.interest"
                    type="text"
                    placeholder="Хүү"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-amount"
                  label="Хүссэн дүн:"
                  label-for="input-amount"
                >
                  <money
                    id="input-amount"
                    class="form-control"
                    v-model="form.amount"
                    type="text"
                    placeholder="Хүссэн дүн"
                    readonly
                  ></money>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-createdDate"
                  label="Хүсэл гаргасан огноо:"
                  label-for="input-createdDate"
                >
                  <b-form-input
                    id="input-createdDate"
                    v-model="form.createdDate"
                    type="text"
                    placeholder="Хүсэл гаргасан огноо"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-bankName"
                  label="Банк:"
                  label-for="input-bankName"
                >
                  <b-form-input
                    id="input-bankName"
                    v-model="form.bankName"
                    type="text"
                    placeholder="Банк"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-group-bankAccount"
                  label="Данс:"
                  label-for="input-bankAccount"
                >
                  <b-form-input
                    id="input-interbankAccountest"
                    v-model="form.bankAccount"
                    type="text"
                    placeholder="Данс"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              id="input-group-status"
              label="Төлөв:"
              label-for="input-status"
            >
              <b-select
                v-model="form.status"
                :options="status"
                placeholder="Төлөв"
                :multiple="false"
              ></b-select>
            </b-form-group>

            <b-form-group
              id="input-group-create-loan"
              label="Зээл үүсгэх:"
              label-for="input-status"
              v-if="form.status == 'SUCCESS'"
            >
              <b-form-checkbox
                v-model="is_create"
                :value="true"
                :unchecked-value="false"
              >
                Зээл үүсгэх бол сонгоно уу
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              id="input-group-description"
              label="Тайлбар:"
              label-for="input-description"
              class="mb-0"
            >
              <b-form-textarea
                id="input-description"
                v-model="form.description"
                placeholder="Тайлбар"
                rows="2"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3"
                >Шинэчлэх</b-button
              >
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Menu.edit",
  data: function() {
    return {
      isLoading: false,
      form: {
        firstname: "",
        lastname: "",
        register: "",
        phone: "",
        term: "",
        interest: "",
        amount: "",
        status: "",
        description: "",
        isCreateLoan: false,
      },
      is_create: false,
      show: true,
      status: ["SUCCESS", "NEW", "CANCEL"],
      save_url: "AdminWebService/create_loan_request",
    };
  },
  created: function() {
    this.checkSession();
    if (this.$route.params.id > 0) {
      this.$data.save_url = "AdminWebService/update_loan_request";
      this.loadData();
    }
  },
  watch: {
    is_create: {
      handler(is_create) {
        this.$data.form.isCreateLoan = is_create;
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "AdminWebService/get_loan_request",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              id: this.$route.params.id,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.form = response.body;
            this.$data.form.isCreateLoan = false;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    onSubmit(evt) {
      this.isLoading = true;
      (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email),
        //console.log(this.$data.form)

        this.$http
          .post(
            this.$config.API_URL + this.$data.save_url,
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              if (this.$route.params.id == 0) {
                this.onReset(evt);
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
    },
    onReset(evt) {
      evt.preventDefault();
      this.$data.form = {
        name: "",
        code: "",
        icon: "",
        action: "",
        isActive: false,
        displayIndex: "",
        roles: "",
        parent: null,
      };
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
